@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500,600&display=swap");

:root {
  --amber: #7E5B4E;
  --amber-light: #A97C65;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  margin: 0;
  background-color: #F1E8DF;
  background-image: url("assets/splash.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60%;

  font-size: 16px;
  font-weight: 400;
  color: #7E5B4E;
  font-family: "Montserrat", sans-serif;
}

@media screen and (min-width: 570px) {
  body {
    background-size: 350px;
  }
}

h1 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--amber);
  font-weight: 600;
  letter-spacing: 2px;
}

h2 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: var(--amber);
  font-weight: 600;
  letter-spacing: 2px;
}

p {
  margin: 0;
  padding: 0;
  line-height: 30px;
}

strong {
  font-weight: 500;
}
a {
  text-decoration: none;
}

*:disabled {
  cursor: default;
  opacity: 0.5;
}

button {
  all: unset;
  width: fit-content;
  border: 2px solid var(--amber);
  color: var(--amber);
  text-transform: uppercase;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 600;
  user-select: none;
}

button:hover {
  background: var(--amber);
  color: #fff;
  transition: 0.1s all;
}

input,
textarea {
  background-color: transparent;
  outline: 0;
  border: 1px solid var(--amber);
  border-radius: 4px;
  padding: 8px 16px;
  resize: vertical;

  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 1px solid var(--amber);
  border-radius: 4px;

  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;


  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.9em;
  height: 0.9em;
  border-radius: 3px;
  transform: scale(0);
  background-color: var(--amber);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
