.backdrop {
  position: fixed;
  overflow-y: auto;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  background: #00000074;
  transition: 0.5s opacity;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  padding: 30px 20px;
}

.modalCard {
  background-color: #F1E8DF;
  box-shadow: 0px 10px 10px -5px #0000000a, 0px 20px 25px -5px #0000001a;
  padding: 125px 50px;
  opacity: 0;
  transform: translateY(-50px);
  background-image: url("../../../assets/splash.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60%;
  border-radius: 6px;
}
